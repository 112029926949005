import type { FC, ComponentType } from 'react';
import React, { useContext, useCallback } from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import { N0, N40 } from '@atlaskit/theme/colors';

import { PageCardContext } from '../PageCardContext';

export type CardProps = {
	isMenuOpen: boolean;
	customStyles?: object;
	isFolderHoverCard?: boolean;
};
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Card = styled.div<CardProps>(
	{
		position: 'relative',
		backgroundColor: token('elevation.surface.raised', N0),
		border: `1px solid ${token('color.border', N40)}`,
		borderRadius: token('border.radius.200', '8px'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		'--show-action': ({ isMenuOpen }) => (isMenuOpen ? 1 : 0),
	},
	// folder hover cards are not interactive
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isFolderHoverCard }) =>
		!isFolderHoverCard && {
			'&:hover,&:active,&:focus-within': {
				cursor: 'pointer',
				'--show-action': 1,
				boxShadow: token(
					'elevation.shadow.raised',
					'0px 1px 1px rgba(9, 30, 66, 0.25), 0px 0px 1px rgba(9, 30, 66, 0.31)',
				),
			},
		},
);

type PageCardContainerProps = {
	StyledCard?: ComponentType<CardProps>;
	children?: React.ReactNode;
	isHoverPageCard?: boolean;
};
export const PageCardContainer: FC<PageCardContainerProps> = ({
	StyledCard = Card,
	children,
	isHoverPageCard,
}) => {
	const { isMenuOpen, setIsViewed, type } = useContext(PageCardContext);
	const handleMouseEnter = useCallback(() => {
		setIsViewed(true);
	}, [setIsViewed]);

	const isFolderHoverCard = isHoverPageCard && type === 'folder';

	return (
		<StyledCard
			isMenuOpen={isMenuOpen}
			onMouseEnter={handleMouseEnter}
			onFocus={handleMouseEnter}
			isFolderHoverCard={isFolderHoverCard}
			// prevent clicking on the card from closing it -- folder hover cards are not interactive
			onClick={(e) => isFolderHoverCard && e.stopPropagation()}
			data-testid="page-card-card-container"
		>
			{children}
		</StyledCard>
	);
};
