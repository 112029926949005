import { createContext } from 'react';

import type { PageCard as PageCardData, PageCardState } from './card-types';
import { QuickActionsEnum } from './Items/QuickActions/QuickActionsEnum';

export const PageCardContext = createContext<PageCardData & PageCardState>({
	id: '',
	title: {
		text: '',
		emoji: '',
	},
	type: 'page',
	subType: null,
	createdDate: undefined,
	createdBy: {
		displayName: '',
		profilePicture: '',
		accountId: '',
	},
	ownedBy: {
		displayName: '',
		profilePicture: '',
		accountId: '',
	},
	space: {
		spaceKey: '',
		name: '',
	},
	lastAction: {
		when: '',
		action: 'visited',
	},
	editorVersion: '',
	isStarred: false,
	links: {
		webui: '',
		editui: '',
	},
	likesCount: 0,
	commentsCount: 0,
	setIsMenuOpen: () => {},
	isMenuOpen: false,
	setSelectedMenuItems: () => {},
	selectedMenuItems: {
		[QuickActionsEnum.ShareButton]: false,
		[QuickActionsEnum.WatchButton]: false,
	},
	appearance: 'grid',
	status: undefined,
	isViewed: false,
	isUnread: false,
	setIsViewed: () => {},
	smallCard: false,
});
