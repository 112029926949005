import { useContext, useCallback } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next/useAnalyticsEvents';

import { useSpaceId } from '@confluence/space-utils';

import { PageCardContext } from '../PageCardContext';

type PayloadType = {
	data: { [key: string]: any };
	[key: string]: any;
};

export const usePageCardAnalyticsEvents = () => {
	const { createAnalyticsEvent: akCreateAnalyticsEvent } = useAnalyticsEvents();
	const { id, ranking, analyticsData, appearance, type } = useContext(PageCardContext);
	const spaceId = useSpaceId();
	const createAnalyticsEvent = useCallback(
		(payload: PayloadType) => {
			payload.data.objectType = type;
			payload.data.objectId = payload.data.objectId ? payload.data.objectId : id;
			payload.data.containerType = 'space';
			payload.data.containerId = spaceId;
			if (appearance === 'popup-view') {
				payload.data.actionSubjectId = 'pageTree';
			}
			payload.data.source = payload.data.source ? payload.data.source : analyticsData?.source;
			payload.data.attributes = {
				...payload.data.attributes,
				...analyticsData?.attributes,
			};

			if (typeof ranking === 'number') {
				payload.data.attributes.ranking = ranking;
			}
			payload.data.attributes.view = appearance;
			return akCreateAnalyticsEvent(payload);
		},
		[id, spaceId, ranking, analyticsData, appearance, type, akCreateAnalyticsEvent],
	);
	return { createAnalyticsEvent };
};
