import type { FC } from 'react';
import React, { useContext } from 'react';
// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { token } from '@atlaskit/tokens';
import { N800 } from '@atlaskit/theme/colors';

import { PageCardContext } from '../PageCardContext';

type SingleLineProp = { singleLine?: boolean };
type CompactProp = { compact?: boolean };
type EndOfPageProp = { endOfPage?: boolean };

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const TitleContainer = styled.div<SingleLineProp & CompactProp & EndOfPageProp>`
	color: ${token('color.text', N800)};
	${(props) =>
		props.compact
			? `
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
    `
			: props.endOfPage
				? `
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
    `
				: `
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
  `}
	text-decoration: none;
	outline: none;

	${(props) =>
		props.singleLine &&
		`
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  `}

	&:active,
  &:visited,
  &:hover,
  &:focus {
		color: ${token('color.text', N800)};
		text-decoration: none;
	}
`;

export const Title: FC<SingleLineProp> = ({ singleLine }) => {
	const {
		id,
		title: { text },
		appearance,
	} = useContext(PageCardContext);
	const compact = appearance === 'compact-list';
	const endOfPage = appearance === 'end-of-page';
	return (
		<TitleContainer
			id={`content-title-${id}`}
			singleLine={singleLine}
			compact={compact}
			endOfPage={endOfPage}
			role="heading"
			aria-level={2}
		>
			{text}
		</TitleContainer>
	);
};
